@import 'shared';
@import '@panel/avatar/styles/mobile';

.brand {
  padding: $andes-spacing-16 0;

  &__img {
    width: 20px;
    height: 20px;
  }

  &__name {
    font-size: $font-size-16;
  }
}
