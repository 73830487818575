.mobile-layout {
  padding: 0 16px;

  .body-container {
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
