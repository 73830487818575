@import './shared';

.modal-billing {
  padding: $andes-spacing-24;
}

.andes-modal--card .andes-modal {
  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__header {
    margin: 0;

    .andes-modal__title {
      font-size: 19px;
      text-align: center;
    }
  }

  &__content {
    padding-top: 24px;
    height: 336px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .modal__description {
      margin-bottom: $andes-spacing-24;
      text-align: center;

      p {
        font-size: $font-size-16;
        text-align: center;
      }
    }

    .milestones-container {
      height: 176px;
    }

    .milestone-child {
      flex-wrap: wrap;
      align-items: center;

      .tag-special-message {
        margin-top: 8px;
      }

      .tag-special-message__green {
        margin-right: 40px;
      }
    }

    ul.milestones {
      padding-left: 22px;
    }

    ul.milestones li::after {
      top: 16px;
    }

    ul.milestones li:last-child::before {
      bottom: 44px;
    }
  }

  &__container {
    max-width: em(264);
    padding-top: $andes-spacing-24;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    margin: $andes-spacing-24 0 0 0;
  }
}

.modal-billing.andes-modal--card .andes-modal__close-button {
  display: none;
}
