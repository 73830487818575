@import './shared';
@import './../../../components/HeaderComponent/styles.mobile';
@import './../../../components/CustomAmountViewComponent/styles/styles.mobile';
@import './../deviceLayouts/mobileLayout/styles';
@import './../../../components/UserHeader/styles/styles.mobile';
@import './../../../components/DisclaimerLinks/styles/styles.mobile';
@import './../../../components/CheckboxTYC/styles/styles.mobile';
@import './../../../components/ModalBillingExplanation/style.mobile';

body {
  height: 100%;
  padding-top: $andes-spacing-64;
  background: $andes-white;
}

.greet {
  max-width: 302px;
  font-size: $font-size-20;
}

.info-texts {
  margin-bottom: $andes-spacing-32;
}
